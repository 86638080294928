import React from "react"
import { StoryGroup } from "components"
import { getEBooksCoverUrl } from "utils/rsc-util"

const eBooks = [
  {
    title: "Fetch API, Axios, GraphQL",
    url: "https://learnreactui.dev/contents/fetch-api-axios-and-graphql",
    img: getEBooksCoverUrl("062-query-fetch-axios-graphql"),
  },
  {
    title: "WebSocket Chat App Example with MSW",
    url: "https://learnreactui.dev/contents/websocket-chat-app-with-msw",
    img: getEBooksCoverUrl("websocket-chat-app-with-msw"),
  },
  {
    title: "CRUD Operations With MSW",
    url: "https://learnreactui.dev/contents/crud-mocking-msw",
    img: getEBooksCoverUrl("063-crud-mocking"),
  },
  {
    title: "React Query Networking with Axios and GraphQL",
    url: "https://learnreactui.dev/contents/react-query-and-networking",
    img: getEBooksCoverUrl("064-rquery_networking"),
  },
]

const LinkMap = {
  "Network Examples": "35c9a1672aca",
  "Crud Examples": "4bb389d83680",
  Axios: "958cab75e45b",
  "HTTP Interceptors": "958cab75e45b",
  "Mock Server": "d9c794146edc",
  "Mock Server Rest API": "16c37afe4447",
  "Mock Server GraphQL": "141792746a44",
}

const stories = [
  {
    title: "Axios Interceptor Kullanım Alanları",
    postId: "958cab75e45b",
    postIdEnglish: "24f1474c9baf",
  },
  {
    title: "(MSW) Mock Server Kullanımı",
    postId: "d9c794146edc",
  },
  {
    title: "(MSW) Mock Server Rest API CRUD Kullanımı",
    postId: "16c37afe4447",
  },
  {
    title: "(MSW) Mock Server GraphQL CRUD Kullanımı",
    postId: "141792746a44",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "React Network",
  path: "react-network",
}

const ReactNetworkPage = () => {
  return (
    <StoryGroup
      eBooks={eBooks}
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactNetworkPage
